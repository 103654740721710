import React, { useState, forwardRef } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import mainTheme from './MainTheme.js'
import { dimensions, metrics, properties } from './googleData'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Container, Box, Typography, Grid, FormControl, InputLabel, Select, MenuItem, Paper, TextField, Button, Snackbar  } from '@mui/material'
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Alert, AlertTitle } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import moment from 'moment'
import SendIcon from '@mui/icons-material/Send'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import MuiAlert from '@mui/material/Alert';

import './App.css'

const uris = [
  { text: 'Prod Server - https://motoza-backend.fidweb.net/', url: 'https://motoza-backend.fidweb.net' },
  { text: 'Dev Server - https://motoza-dev.herokuapp.com/', url: 'https://motoza-dev.herokuapp.com' },
  { text: 'localhost', url: 'http://localhost:5000' },
]

const Info = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})


const App = () => {
  const [property, setProperty] = useState(properties[0].id)
  const [dimension, setDimension] = useState(dimensions[0])
  const [metric, setMetric] = useState(metrics[0])
  const [startDate, setStartDate] = useState(moment().subtract(1, 'week').toDate())
  const [endDate, setEndDate] = useState(new Date())
  const [rows, setRows] = useState([])
  const [alert, setAlert]= useState({ visible: false, severity: 'success', text: 'Success result' })
  const [url, setUrl] = useState(uris[0].url)
  const [activeRequest, setActiveRequest] = useState(false)
  const [showSnack, setShowSnack] = useState(false)

  const requestAuth = () => {
    setActiveRequest(false)

    fetch(`${url}/reports/auth`)
    .then(x => x.json())
    .then(result => window.open(result.payload, '_blank', 'noopener,noreferrer'))
    .catch(e => console.log(e.message))
  }

  const sendRequest = () => {
    setActiveRequest(true)
    setAlert({ visible: false, severity: 'success', text: 'Successful request' })
    const body = {
      property,
      dimension,
      metric,
      startDate: moment(startDate).format('YYYY-MM-DD').toString(),
      endDate: moment(endDate).format('YYYY-MM-DD').toString()
    }

    fetch(`${url}/reports/test`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    })
    .then(x => {
      if (x.status === 406) requestAuth()
      // if (x.status === 200) return x.json()
      return x.json()
    })
    .then(result => {
      setActiveRequest(false)
      if (result.success === false) throw new Error(result.message)
      setRows(result.payload)
      setAlert({ visible: true, severity: 'success', text: 'Successful request' })
    })
    .catch(e => {
      setActiveRequest(false)
      setAlert({ visible: true, severity: 'error', text: e.message })
    })
  }

  const closeSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setShowSnack(false)
  }

  const copyCombination = () => {
    navigator.clipboard.writeText(`{ dimension: ${dimension}, metric: ${metric} }`)
    setShowSnack(true)
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <Container maxWidth='lg' sx={{p: 2}}>
        <Box sx={{ borderBottom: `1px solid ${mainTheme.palette.primary.main}`, mb: 3, display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='h5' color={mainTheme.palette.primary.main}>Google Analytics Data API</Typography>
            <FormControl sx={{pt: 0.4, mr: -1}}>
              <Select
                variant='standard'
                disableUnderline
                size='small'
                sx={{ color: mainTheme.palette.primary.main }}
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              >
                { uris.map(el => <MenuItem key={el.text} value={el.url}>{el.text}</MenuItem>) }
              </Select>
            </FormControl>
        </Box>
          <Grid container spacing={6}>
            <Grid item xs={4}>
              <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel>Property</InputLabel>
                <Select
                  size='small'
                  label='Property'
                  value={property}
                  onChange={(e) => setProperty(e.target.value)}
                >
                  { properties.map(el => <MenuItem key={el.id} value={el.id}>{`${el.name} - ${el.id}`}</MenuItem>) }
                  
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel>Dimension</InputLabel>
                <Select
                  size='small'
                  label='Dimension'
                  value={dimension}
                  onChange={(e) => setDimension(e.target.value)}
                >
                  { dimensions.map((el, index) => <MenuItem key={index} value={el}>{el}</MenuItem>) }
                  
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mt: 3, mb: 3 }}>
                <InputLabel>Metric</InputLabel>
                <Select
                  size='small'
                  label='Metric'
                  value={metric}
                  onChange={(e) => setMetric(e.target.value)}
                >
                  { metrics.map((el, index) => <MenuItem key={index} value={el}>{el}</MenuItem>) }
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label='Start date'
                  inputFormat='DD-MM-YYYY'
                  value={startDate}
                  onChange={setStartDate}
                  renderInput={(params) => <TextField { ...params } fullWidth size='small' sx={{ mb: 3, svg: { color: mainTheme.palette.primary.main} }} />}
                />
                <DesktopDatePicker
                  label='End date'
                  inputFormat='DD-MM-YYYY'
                  value={endDate}
                  onChange={setEndDate}
                  renderInput={(params) => <TextField { ...params } fullWidth size='small' sx={{ mb: 3, svg: { color: mainTheme.palette.primary.main} }} />}
                  
                />
              </LocalizationProvider>
              <LoadingButton endIcon={<SendIcon />} loading={activeRequest} variant='contained' fullWidth onClick={sendRequest}>Send Request</LoadingButton>
              <Button endIcon={<ContentCopyIcon />}  variant='contained' fullWidth sx={{mt: 3}} onClick={copyCombination}>Copy Combination</Button>
              {
                !alert.visible
                  ? null
                  : <Alert severity={alert.severity} sx={{mt: 3}}>
                      <AlertTitle>{ alert.severity === 'success' ? 'Success request' : 'Error' }</AlertTitle>
                      { alert.text }
                    </Alert>
              }
            </Grid>
            <Grid item xs={8}>
              <TableContainer component={Paper} sx={{overflow: 'auto', maxHeight: '85vh'}}>
                <Table size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Dimension</TableCell>
                      <TableCell>Metric</TableCell>
                    </TableRow>
                  </TableHead>
                    <TableBody>
                      {
                        rows.length
                          ? rows.map((el, index) => <TableRow key={index}><TableCell>{el.dimension}</TableCell><TableCell>{el.metric}</TableCell></TableRow>)
                          : <TableRow><TableCell>no data</TableCell><TableCell>no data</TableCell></TableRow>
                      }
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
      </Container>
      <Snackbar open={showSnack} autoHideDuration={3000} onClose={closeSnack}>
        <Info onClose={closeSnack} severity="success" sx={{ width: '100%' }}>
          Combination copy to clipboard
        </Info>
      </Snackbar>
    </ThemeProvider>
  )
  
}

export default App
