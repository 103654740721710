import { createTheme } from '@mui/material/styles'
import { lightGreen } from '@mui/material/colors';


const mainTheme = createTheme({
  palette: {
    primary: {
      main: lightGreen[700]
    },
    secondary: {
      main: '#000000'      
    },
  },
  
});

export default mainTheme