export const dimensions = [
  'achievementId',
  'adFormat',
  'adSourceName',
  'adUnitName',
  'appVersion',
  'audienceId',
  'audienceName',
  'brandingInterest',
  'browser',
  'campaignId',
  'campaignName',
  'character',
  'city',
  'cityId',
  'cohort',
  'cohortNthDay',
  'cohortNthMonth',
  'cohortNthWeek',
  'contentGroup',
  'contentId',
  'contentType',
  'country',
  'countryId',
  'date',
  'dateHour',
  'dateHourMinute',
  'day',
  'dayOfWeek',
  'defaultChannelGrouping',
  'deviceCategory',
  'deviceModel',
  'eventName',
  'fileExtension',
  'fileName',
  'firstSessionDate',
  'firstUserCampaignId',
  'firstUserCampaignName',
  'firstUserDefaultChannelGrouping',
  'firstUserGoogleAdsAccountName',
  'firstUserGoogleAdsAdGroupId',
  'firstUserGoogleAdsAdGroupName',
  'firstUserGoogleAdsAdNetworkType',
  'firstUserGoogleAdsCampaignId',
  'firstUserGoogleAdsCampaignName',
  'firstUserGoogleAdsCampaignType',
  'firstUserGoogleAdsCreativeId',
  'firstUserGoogleAdsCustomerId',
  'firstUserGoogleAdsKeyword',
  'firstUserGoogleAdsQuery',
  'firstUserManualAdContent',
  'firstUserManualTerm',
  'firstUserMedium',
  'firstUserSource',
  'firstUserSourceMedium',
  'firstUserSourcePlatform',
  'fullPageUrl',
  'googleAdsAccountName',
  'googleAdsAdGroupId',
  'googleAdsAdGroupName',
  'googleAdsAdNetworkType',
  'googleAdsCampaignId',
  'googleAdsCampaignName',
  'googleAdsCampaignType',
  'googleAdsCreativeId',
  'googleAdsCustomerId',
  'googleAdsKeyword',
  'googleAdsQuery',
  'groupId',
  'hostName',
  'hour',
  'isConversionEvent',
  'itemAffiliation',
  'itemBrand',
  'itemCategory',
  'itemCategory2',
  'itemCategory3',
  'itemCategory4',
  'itemCategory5',
  'itemId',
  'itemListId',
  'itemListName',
  'itemName',
  'itemPromotionCreativeName',
  'itemPromotionId',
  'itemPromotionName',
  'itemVariant',
  'landingPage',
  'language',
  'languageCode',
  'level',
  'linkClasses',
  'linkDomain',
  'linkId',
  'linkText',
  'linkUrl',
  'manualAdContent',
  'manualTerm',
  'medium',
  'method',
  'minute',
  'mobileDeviceBranding',
  'mobileDeviceMarketingName',
  'mobileDeviceModel',
  'month',
  'newVsReturning',
  'nthDay',
  'nthHour',
  'nthMinute',
  'nthMonth',
  'nthWeek',
  'nthYear',
  'operatingSystem',
  'operatingSystemVersion',
  'operatingSystemWithVersion',
  'orderCoupon',
  'outbound',
  'pageLocation',
  'pagePath',
  'pagePathPlusQueryString',
  'pageReferrer',
  'pageTitle',
  'percentScrolled',
  'platform',
  'platformDeviceCategory',
  'region',
  'screenResolution',
  'searchTerm',
  'sessionCampaignId',
  'sessionCampaignName',
  'sessionDefaultChannelGrouping',
  'sessionGoogleAdsAccountName',
  'sessionGoogleAdsAdGroupId',
  'sessionGoogleAdsAdGroupName',
  'sessionGoogleAdsAdNetworkType',
  'sessionGoogleAdsCampaignId',
  'sessionGoogleAdsCampaignName',
  'sessionGoogleAdsCampaignType',
  'sessionGoogleAdsCreativeId',
  'sessionGoogleAdsCustomerId',
  'sessionGoogleAdsKeyword',
  'sessionGoogleAdsQuery',
  'sessionManualAdContent',
  'sessionManualTerm',
  'sessionMedium',
  'sessionSa360AdGroupName',
  'sessionSa360CampaignId',
  'sessionSa360CampaignName',
  'sessionSa360CreativeFormat',
  'sessionSa360EngineAccountId',
  'sessionSa360EngineAccountName',
  'sessionSa360EngineAccountType',
  'sessionSa360Keyword',
  'sessionSa360Medium',
  'sessionSa360Query',
  'sessionSa360Source',
  'sessionSource',
  'sessionSourceMedium',
  'sessionSourcePlatform',
  'shippingTier',
  'signedInWithUserId',
  'source',
  'sourceMedium',
  'sourcePlatform',
  'streamId',
  'streamName',
  'testDataFilterName',
  'transactionId',
  'unifiedPagePathScreen',
  'unifiedPageScreen',
  'unifiedScreenClass',
  'unifiedScreenName',
  'userAgeBracket',
  'userGender',
  'videoProvider',
  'videoTitle',
  'videoUrl',
  'virtualCurrencyName',
  'visible',
  'week',
  'year'
]


export const metrics = [
  'active1DayUsers',
  'active28DayUsers',
  'active7DayUsers',
  'activeUsers',
  'adUnitExposure',
  'addToCarts',
  'advertiserAdClicks',
  'advertiserAdCost',
  'advertiserAdCostPerClick',
  'advertiserAdCostPerConversion',
  'advertiserAdImpressions',
  'averagePurchaseRevenue',
  'averagePurchaseRevenuePerPayingUser',
  'averagePurchaseRevenuePerUser',
  'averageRevenuePerUser',
  'averageSessionDuration',
  'bounceRate',
  'cartToViewRate',
  'checkouts',
  'cohortActiveUsers',
  'cohortTotalUsers',
  'conversions',
  'crashAffectedUsers',
  'crashFreeUsersRate',
  'dauPerMau',
  'dauPerWau',
  'ecommercePurchases',
  'engagedSessions',
  'engagementRate',
  'eventCount',
  'eventCountPerUser',
  'eventValue',
  'eventsPerSession',
  'firstTimePurchaserConversionRate',
  'firstTimePurchasers',
  'firstTimePurchasersPerNewUser',
  'itemListClickEvents',
  'itemListClickThroughRate',
  'itemListViewEvents',
  'itemPromotionClickThroughRate',
  'itemRevenue',
  'itemViewEvents',
  'itemsAddedToCart',
  'itemsCheckedOut',
  'itemsClickedInList',
  'itemsClickedInPromotion',
  'itemsPurchased',
  'itemsViewed',
  'itemsViewedInList',
  'itemsViewedInPromotion',
  'newUsers',
  'organicGoogleSearchAveragePosition',
  'organicGoogleSearchClickThroughRate',
  'organicGoogleSearchClicks',
  'organicGoogleSearchImpressions',
  'promotionClicks',
  'promotionViews',
  'publisherAdClicks',
  'publisherAdImpressions',
  'purchaseRevenue',
  'purchaseToViewRate',
  'purchaserConversionRate',
  'returnOnAdSpend',
  'screenPageViews',
  'screenPageViewsPerSession',
  'sessionConversionRate',
  'sessions',
  'sessionsPerUser',
  'shippingAmount',
  'taxAmount',
  'totalAdRevenue',
  'totalPurchasers',
  'totalRevenue',
  'totalUsers',
  'transactions',
  'transactionsPerPurchaser',
  'userConversionRate',
  'userEngagementDuration',
  'wauPerMau',
]

export const properties = [
  { name: 'www.lakehouses4sale.net', id: '292680749' },
  { name: 'www.mynbhome.com', id: '293103321'},
  { name: 'I VIE WEB', id: '299549606'},
  { name: 'texasturfusa.com', id: '311972610'},
  { name: 'ThermaSol (2016)', id: '319553902'},
]